import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { ROUTER_NAME_ADMIN_PAGE } from "../../../utils/browserRouter/routesNames";
import { copyTextClipboard, generateStrongPassword, validateEmail, validatePassword } from "../../../utils/routines/routines";

// 
interface FormInterface {
  email: string;
  name: string;
  surname: string;
  password: string;
}

const AddAdmin: React.FC = () => {
  const [formData, setFormData] = useState<Partial<FormInterface>>({ password: "" });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const navigate = useNavigate();

  function handleFormSubmit(e: React.FormEvent<HTMLElement>) {
    try {
      e.preventDefault();
      navigate(ROUTER_NAME_ADMIN_PAGE);
      // if (formData && formData.name && formData.name.length > 1 && formData.surname && formData.surname.length > 1) {
      //   if (formData.email && validateEmail(formData.email) === 1) {
      //     if (formData.password && formData.password.length >= 8) {
      //       const validPwd: string | boolean = validatePassword(formData.password);
      //       if (validPwd === true) {
      //         navigate(ROUTER_NAME_ADMIN_PAGE);
      //       }
      //       else {
      //         alert(`${validPwd}`);
      //       }
      //     }
      //     else {
      //       alert("Password must be more than 8 characters long");
      //     }
      //   }
      //   else {
      //     alert("Invalid email");
      //   }
      // }
      // else {
      //   alert("Invalid name");
      // }
    }
    catch (err: any) {
      console.log(err);
    }
  }

  function handleShowPassword(e: React.FormEvent<HTMLElement>) {
    try {
      e.preventDefault();
      setShowPassword(!showPassword);
    }
    catch (err: any) {
      console.log(err);
    }
  }

  function handleGeneratePasswordBtn(e: React.FormEvent<HTMLElement>): void {
    try {
      e.preventDefault()
      const gen_pwd: string = generateStrongPassword();
      // console.log(gen_pwd);
      setFormData({ ...formData, password: gen_pwd });
    }
    catch (err: any) {
      console.log(err);
    }
  }

  function handleCopyPasswordBtn(e: React.FormEvent<HTMLElement>): void {
    try {
      e.preventDefault()
      if (formData && formData.password && formData.password.length > 0) copyTextClipboard(formData.password, "password");
    }
    catch (err: any) {
      alert(`Error copying text to clipboard: ${err}`);
      console.log(err);
    }
  }

  return <div className=" px-[1rem] " >

    <div className=" mx-auto max-w-[800px]" >

      <div className=" pt-[2rem] mb-[2rem] text-3xl " >Add Admin</div>

      <form
      // onSubmit={(e: React.FormEvent<HTMLElement>) => handleFormSubmit(e)} 
      >
        <div className=" div_formfield flex flex-col " >
          <label >Name
            <span className="text-red-600" > *</span>
          </label>
          <input onChange={(e) => setFormData({ ...formData, name: e.target.value })} placeholder="Enter name" />
        </div>

        <div className=" div_formfield flex flex-col " >
          <label >Surname
            <span className="text-red-600" > *</span>
          </label>
          <input onChange={(e) => setFormData({ ...formData, surname: e.target.value })} placeholder="Enter surname" />
        </div>

        <div className=" div_formfield flex flex-col " >
          <label >Email
            <span className="text-red-600" > *</span>
          </label>
          <input type="email" onChange={(e) => setFormData({ ...formData, email: e.target.value })} placeholder="Enter email" />
        </div>

        <div className=" flex flex-col " >
          <label >Password
            <span className="text-red-600" > *</span>
          </label>
          <div className=" flex justify-between" >
            <input className="w-[90%] mr-[0.5rem] "
              value={formData?.password}
              type={showPassword ? "text" : "password"}
              onChange={(e) => setFormData({ ...formData, password: e.target.value })}
              placeholder="Enter password" />
            <button className="w-1/12" onClick={(e) => handleShowPassword(e)} >show</button>
          </div>
          <div className=" flex justify-end " >
            {
              formData.password && formData.password.length > 8 &&
              <button className=" w-3/12 mt-[0.8rem] mx-[1rem] " onClick={(e: React.FormEvent<HTMLElement>) => handleCopyPasswordBtn(e)} >Copy Password</button>
            }
            <button className=" w-4/12 mt-[0.8rem] " onClick={(e: React.FormEvent<HTMLElement>) => handleGeneratePasswordBtn(e)} >Generate Password</button>
          </div>
        </div>

        {/*  */}
        <button className=" mt-[4rem] w-full" onClick={(e: React.FormEvent<HTMLElement>) => handleFormSubmit(e)} >Submit</button>
      </form>

      {/* <div className=" mt-[3rem]" >
        <button className=" w-full" onClick={(e) => handleRegisterAdmin()} >Register Admin</button>
      </div> */}
    </div >
  </div >
}

export default AddAdmin;