import { validateEmail, validateSAPhoneNumber } from "../../../utils/routines/routines";
import { validateFormReturn } from "./interfaces"


export function validateSurveyForm(data: any): validateFormReturn {
  try {
    if (data) {
      // Validate airConditioning field
      if (!data.airConditioning) {
        return { message: "Invalid airConditioning", status: false };
      }

      // Validate cameraSafety field
      if (!data.cameraSafety) {
        return { message: "Invalid cameraSafety", status: false };
      }

      // Validate collegeAddress
      if (!data.collegeAddress) {
        return { message: "Invalid college address", status: false };
      }

      // Validate collegeLocation
      if (!data.collegeLocation) {
        return { message: "Invalid collegeLocation", status: false };
      }

      // Validate collegeProvince
      if (!data.collegeProvince) {
        return { message: "Invalid collegeProvince", status: false };
      }

      // Validate collegeType
      if (!data.collegeType) {
        return { message: "Invalid collegeType", status: false };
      }

      // Validate commentSuggestions
      if (!data.commentSuggestions || data.commentSuggestions.length < 3) {
        return { message: "Invalid commentSuggestions", status: false };
      }

      // Validate complainsLogged
      if (data.complainsLogged !== "yes" && data.complainsLogged !== "no") {
        return { message: "Invalid complainsLogged", status: false };
      }

      // Validate computerLaptop field
      if (!data.computerLaptop) {
        return { message: "Invalid computerLaptop", status: false };
      }

      // Validate electricityAvailable field
      if (!data.electricityAvailable) {
        return { message: "Invalid electricityAvailable", status: false };
      }

      // Validate emailICTLabLecturer
      if (!data.emailICTLabLecturer || !data.emailICTLabLecturer.includes("@")) {
        const isValidEmail = validateEmail(data.collegeAddress);
        if (isValidEmail !== 1)
          return { message: "Invalid emailICTLabLecturer", status: false };
      }

      // Validate infrasElectricity
      if (!data.infrasElectricity) {
        return { message: "Invalid infrasElectricity", status: false };
      }

      // Validate labAccessDuration
      if (!data.labAccessDuration) {
        return { message: "Invalid labAccessDuration", status: false };
      }

      // Validate labOperational field
      if (data.labOpoerational !== "yes" && data.labOpoerational !== "no") {
        return { message: "Invalid labOperational", status: false };
      }

      // Validate nameICTLabLecturer
      if (!data.nameICTLabLecturer) {
        return { message: "Invalid nameICTLabLecturer", status: false };
      }

      // Validate networking
      if (!data.networking) {
        return { message: "Invalid networking", status: false };
      }

      // Validate numberDesktopCollege
      if (!data.numberDesktopCollege || isNaN(Number(data.numberDesktopCollege))) {
        return { message: "Invalid numberDesktopCollege", status: false };
      }

      // Validate numberLaptopCollege
      if (!data.numberLaptopCollege || isNaN(Number(data.numberLaptopCollege))) {
        return { message: "Invalid numberLaptopCollege", status: false };
      }

      // Validate numberStudentAccessLab
      if (!data.numberStudentAccessLab || isNaN(Number(data.numberStudentAccessLab))) {
        return { message: "Invalid numberStudentAccessLab", status: false };
      }

      // Validate numberTeachICTSkill
      if (!data.numberTeachICTSkill || isNaN(Number(data.numberTeachICTSkill))) {
        return { message: "Invalid numberTeachICTSkill", status: false };
      }

      // Validate phoneNumberICTLabLecturer
      if (!data.phoneNumberICTLabLecturer || !/^\d{10}$/.test(data.phoneNumberICTLabLecturer)) {
        const isValidSAPhoneNumber = validateSAPhoneNumber(data.phoneNumberICTLabLecturer);
        if (isValidSAPhoneNumber !== 1)
          return { message: "Invalid phoneNumberICTLabLecturer", status: false };
      }

      // Validate projectors field
      if (!data.projectors) {
        return { message: "Invalid projectors", status: false };
      }

      // Validate rateMaintainSatisfaction
      if (!data.rateMaintainSatisfaction || isNaN(Number(data.rateMaintainSatisfaction))) {
        return { message: "Invalid rateMaintainSatisfaction", status: false };
      }

      // Validate rateMaintenanceFixed
      if (!data.rateMaintenanceFixed) {
        return { message: "Invalid rateMaintenanceFixed", status: false };
      }

      // Validate wifiInstalled field
      if (data.wifiInstalled !== "yes" && data.wifiInstalled !== "no") {
        return { message: "Invalid wifiInstalled", status: false };
      }

      // All fields are valid
      return { message: "Form is valid", status: true };
    } else {
      return { message: "form data is null", status: false };
    }
  } catch (err: any) {
    return { message: err.message, status: false };
  }
}
