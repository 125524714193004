import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import Navbar from "../../components/navbar";
import { COURSES } from "../../utils/data/courses";
import { COURSE_INTERFACE } from "../../utils/interfaces/coursesLectures";
import { ROUTER_NAME_COURSE } from "../../utils/browserRouter/routesNames";

const CoursesHome: React.FC = () => {
  const [courses, setCourses] = useState<any | null>(null);
  const navigate = useNavigate();


  function handleCourseClick(course_idx: number, course_name: string) {
    try {
      const coursePath = ROUTER_NAME_COURSE.replace(":id", course_name.toString()).toLowerCase().replace(/\s+/g, '');
      navigate(coursePath, { state: { name: course_name, idx: course_idx } });
      // navigate(`/courses/${course_idx}`);
    }
    catch (err: any) {
      console.log(err);
    }
  }

  useEffect(() => {
    setCourses(COURSES);
  }, []);

  return <div className="   " >
    <Navbar />

    <div className=" px-[1rem] pt-[1rem] mx-auto max-w-[800px] " >

      <div className=" py-[1rem] " >
        {
          courses && courses.length > 0 && courses.map((course: COURSE_INTERFACE, idx: number) => (
            <div className=" text-white hover:bg-el_green bg-el_darkgrey mb-[1rem] flex flex-row p-[0.3rem] rounded-[0.7rem] " key={idx} >
              <img onClick={() => handleCourseClick(idx, course.name)} className=" w-4/12 cursor-pointer h-[11rem] rounded-[0.6rem] " src={course?.image} />
              <div className=" w-8/12  pl-[1rem] flex flex-col justify-between" >
                <div className=" " >
                  {/* name */}
                  <div onClick={() => handleCourseClick(idx, course.name)} className=" cursor-pointer text-2xl pb-[1rem] hover:underline focus:underline " >{course.name}</div>
                  <div >{course.desc}</div>
                </div>
                {/* other option,. bookmark */}
                <div onClick={() => alert("working on it")} className=" hover:text-el_darkgrey cursor-pointer pb-[1rem] underline focus:underline" >
                  BM Button
                </div>
              </div>


            </div>
          ))
        }
      </div>
    </div>
  </div>
}


export default CoursesHome;