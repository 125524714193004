import RadioButton from "../../../../components/radioBtnComponent";
import { FormComponentProps } from "../interfaces";


const InfrastructorForm: React.FC<FormComponentProps> = ({ formData, setFormData }) => {

  return <div className="" >
    <div className=" divform flex flex-col" >
      <label >Number of number laptop at college *</label>
      <input placeholder="Enter number of laptop at college"
        className=" w-full"
        type="number"
        min={0}
        onChange={(e) => setFormData({ ...formData, "numberLaptopCollege": e.target.value })} />
    </div>

    <div className=" divform flex flex-col" >
      <label >Number of number desktop at college *</label>
      <input placeholder="Enter number of desktop at college"
        className=" w-full"
        type="number"
        min={0}
        onChange={(e) => setFormData({ ...formData, "numberDesktopCollege": e.target.value })} />
    </div>

    <div className=" divform flex flex-col">
      <label >Is WiFi installed in the lab *</label>
      <div>
        <RadioButton
          label="Yes"
          value="yes"
          isSelected={formData?.wifiInstalled === "yes"}
          onChange={(e) => setFormData({ ...formData, "wifiInstalled": e })}
        />
        <RadioButton
          label="No"
          value="no"
          isSelected={formData?.wifiInstalled === "no"}
          onChange={(e) => setFormData({ ...formData, "wifiInstalled": e })}
        />
      </div>
    </div>

    <div className=" divform flex flex-col">
      <label >Is the electricity and infrastructure in good condition *</label>
      <div>
        <RadioButton
          label="Yes"
          value="yes"
          isSelected={formData?.electricityAvailable === "yes"}
          onChange={(e) => setFormData({ ...formData, "electricityAvailable": e })}
        />
        <RadioButton
          label="No"
          value="no"
          isSelected={formData?.electricityAvailable === "no"}
          onChange={(e) => setFormData({ ...formData, "electricityAvailable": e })}
        />
      </div>
    </div>
  </div>
}

export default InfrastructorForm;