import { COURSES_LECTURE_DATA_PROPS } from "../interfaces/coursesLectures";


export const COURSES_LECTURE_DATA: COURSES_LECTURE_DATA_PROPS[] = [
  {
    unit: "welcome",
    name: "welcome to digital literacy",
    url: "https://zaiocontent.s3.eu-west-2.amazonaws.com/digital-literacy/compressed/1.+Welcome+to+Digital+Literacy.mp4"
  },
  {
    unit: "Some Theory",
    name: "Computer, Hardware and Software",
    url: "https://zaiocontent.s3.eu-west-2.amazonaws.com/digital-literacy/compressed/2.+Computer%2C+Hardware+and+Software.mp4"
  },
  {
    unit: "Some Theory",
    name: "The internet and more",
    url: "https://zaiocontent.s3.eu-west-2.amazonaws.com/digital-literacy/compressed/3.+The+internet+and+more.mp4"
  },
  {
    unit: "Folders and Installations",
    name: "Folder structures and copy pasting",
    url: "https://zaiocontent.s3.eu-west-2.amazonaws.com/digital-literacy/compressed/4.+Folder+structures+and+copy+pasting.mp4"
  },
  {
    unit: "Folders and Installations",
    name: "Folder hierarchy, and changing folders from other apps",
    url: "https://zaiocontent.s3.eu-west-2.amazonaws.com/digital-literacy/compressed/5.+Folder+hierarchy%2C+and+changing+folders+from+other+apps.mp4"
  },
  {
    unit: "Folders and Installations",
    name: "Installing Tools",
    url: "https://zaiocontent.s3.eu-west-2.amazonaws.com/digital-literacy/compressed/5.+Installing+tools+(after+folder+hierarchy).mp4"
  },
  {
    unit: "Communication tools and techniques",
    name: "Email etiquette with Gmail",
    url: "https://zaiocontent.s3.eu-west-2.amazonaws.com/digital-literacy/compressed/6.+Email+etiquette+with+Gmail.mp4"
  },
  {
    unit: "Communication tools and techniques",
    name: "Communication with Whatsapp and slack",
    url: "https://zaiocontent.s3.eu-west-2.amazonaws.com/digital-literacy/compressed/7.+Communication+with+Whatsapp+and+slack+-+to+make+a+video+for+adding+attachments+on+wtapp+and+email.mp4"
  },
  {
    unit: "Communication tools and techniques",
    name: "Attaching images for better communication",
    url: "https://zaiocontent.s3.eu-west-2.amazonaws.com/digital-literacy/compressed/8.+Using+whatsapp+to+attach+images+for+better+communication.mp4"
  },
  {
    unit: "Communication tools and techniques",
    name: "Using video conferencing",
    url: "https://zaiocontent.s3.eu-west-2.amazonaws.com/digital-literacy/compressed/9.+Using+video+conferencing+software.mp4"
  },
  {
    unit: "Communication tools and techniques",
    name: "Using Google's tools",
    url: "https://zaiocontent.s3.eu-west-2.amazonaws.com/digital-literacy/compressed/10.+Using+Google's+tools.mp4"
  },
  {
    unit: "Typing",
    name: "Typing tips",
    url: "https://zaiocontent.s3.eu-west-2.amazonaws.com/digital-literacy/compressed/11.+Typing+tips.mp4"
  },
  {
    unit: "Typing",
    name: "Typing practise",
    url: "https://zaiocontent.s3.eu-west-2.amazonaws.com/digital-literacy/compressed/12.+Typing+practise.mp4"
  },
  {
    unit: "Jobs and next steps",
    name: "Mindset and tips to attract jobs",
    url: "https://zaiocontent.s3.eu-west-2.amazonaws.com/digital-literacy/compressed/12.+Mindset+and+tips+to+attract+jobs.mp4"
  },
  {
    unit: "Jobs and next steps",
    name: "Power of posting and next steps",
    url: "https://zaiocontent.s3.eu-west-2.amazonaws.com/digital-literacy/compressed/13.+Power+of+posting+and+next+steps.mp4"
  }
];
