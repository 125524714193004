import React from 'react';

interface SelectProps {
  options: { value: string; label: string }[];
  onChange: (value: string) => void;
  label?: string;
}

const SelectComponent: React.FC<SelectProps> = ({ options, onChange, label }) => {
  return (
    <div className="flex flex-col">
      {label && <label className="text-gray-700 ">
        {label} <span className=' text-red-600' >*</span>
      </label>}
      <select
        defaultValue={label}
        className=" border border-gray-300 rounded-md p-2 text-gray-700 bg-white focus:ring-2 "
        onChange={(e) => onChange(e.target.value)}
      >
        <option disabled={true}> {label}</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectComponent;
