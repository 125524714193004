import { useLocation, useNavigate } from "react-router-dom";
import VideoPlayerComponent from "../../components/videoComponent";
import { COURSES_LECTURE_DATA_PROPS } from "../../utils/interfaces/coursesLectures";
import { COURSES_LECTURE_DATA } from '../../utils/data/courses.';
import { useEffect, useState } from 'react';
import { ROUTER_NAME_QUIZ_PAGE } from "../../utils/browserRouter/routesNames";
import Navbar from "../../components/navbar";

// 
interface CURRENT_VIDEO_INTERFACE {
  video: COURSES_LECTURE_DATA_PROPS;
  idx: number;
}

const Course: React.FC = () => {
  const [currentVideo, setCurrentVideo] = useState<CURRENT_VIDEO_INTERFACE | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { idx, name } = location.state || {};


  function handleNextVideoBtn() {
    try {
      let cv_idx: number | undefined = currentVideo?.idx;
      if (cv_idx || cv_idx === 0 && cv_idx >= 0 && cv_idx < COURSES_LECTURE_DATA?.length - 1) {
        // set current video to next video
        cv_idx += 1;
        setCurrentVideo({ video: COURSES_LECTURE_DATA[cv_idx], idx: cv_idx });
      }
    }
    catch (err: any) {
      console.log(err);
    }
  }

  function handlePrevVideoBtn() {
    try {
      let cv_idx: number | undefined = currentVideo?.idx;
      if (cv_idx && cv_idx > 0) {
        // set current video to prev video
        cv_idx -= 1;
        setCurrentVideo({ video: COURSES_LECTURE_DATA[cv_idx], idx: cv_idx });
      }
    }
    catch (err: any) {
      console.log(err);
    }
  }

  function handleQuizBtn() {
    try {
      navigate(ROUTER_NAME_QUIZ_PAGE);
    }
    catch (err: any) {
      console.log(err);
    }
  }

  useEffect(() => {
    // set first video to show when page load
    // future versions, set video based on last user viewed video
    setCurrentVideo({ video: COURSES_LECTURE_DATA[0], idx: 0 });
    // 
    //  
    // 
  }, []);


  return <div className=" " >

    <Navbar />

    <div className=" mx-auto max-w-[800px] px-[1rem] " >

      <div className=" px-[0.5rem] mt-[0.5rem] mb-[1rem] bg-el_green py-[0.3rem] text-white flex flex-row justify-between items-center " >
        <div className=" text-2xl capitalize " >Digital Literacy Course</div>
        <div className=" " >{currentVideo && currentVideo?.idx + 1} of {COURSES_LECTURE_DATA.length}</div>
      </div>

      {currentVideo?.video ? <div className=" rounded-[0.5rem] " >
        <VideoPlayerComponent
          src={currentVideo?.video.url}
          width="800px"
          height="360px"
          autoPlay
          muted
        />
        <div className=" flex flex-row capitalize pt-[0.5rem] pl-[1rem] " >
          <div className=" mr-[0.5rem] " >{currentVideo?.video.unit} </div> -
          <div className=" ml-[0.5rem] " > {currentVideo?.video.name}</div>
        </div>
      </div> : <div className="" >no video</div>}

      {/*  */}
      {/* btns for next and prev video */}
      <div className=" flex justify-between mt-[1.5rem] " >
        {/* previous video btn */}
        <button disabled={currentVideo?.idx === 0 ? true : false}
          className=" w-5/12"
          onClick={() => handlePrevVideoBtn()} >Previous</button>
        {/* next video btn */}
        {
          currentVideo?.idx && currentVideo?.idx >= COURSES_LECTURE_DATA?.length - 1 ? <button className=" w-5/12" onClick={() => handleQuizBtn()} >Attempt Quiz</button> : <button disabled={currentVideo?.idx && currentVideo?.idx >= COURSES_LECTURE_DATA?.length - 1 ? true : false}
            className=" w-5/12"
            onClick={() => handleNextVideoBtn()} >Next</button>
        }

      </div>

      {/* show quizzes when its the last video */}
      {/* <div className=" mt-[2rem] mb-[3rem] " >
        {
          currentVideo?.idx && currentVideo?.idx >= COURSES_LECTURE_DATA?.length - 1 ? <button className=" w-full" onClick={() => handleQuizBtn()} >Attempt Quiz</button> : ""
        }
      </div> */}
      {/* end button for quiz */}
    </div>
  </div>
}


export default Course;