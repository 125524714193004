import SelectComponent from "../../../../components/selectComponent";
import { CollegeProvince, GENDER_OPTIONS } from "../../../../utils/data/constants";

interface StudentFormProps {
  setFormData: (v: any) => void;
  formData: any;
}


const StudentForm: React.FC<StudentFormProps> = ({ setFormData, formData }) => {

  return <div className="" >
    {/* name */}
    <div className=" flex flex-col mb-[1rem] " >
      <label >Name <span className=' text-red-600' >*</span></label>
      <input placeholder="Enter name" onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
    </div>

    {/* lastname */}
    <div className=" flex flex-col mb-[1rem] " >
      <label >Surname <span className=' text-red-600' >*</span></label>
      <input placeholder="Enter surname" onChange={(e) => setFormData({ ...formData, surname: e.target.value })} />
    </div>
    {/* date of birth */}
    <div className=" flex flex-col mb-[1rem] " >
      <label >Date of birth <span className=' text-red-600' >*</span></label>
      <input type="date" placeholder="Enter date of birth" onChange={(e) => setFormData({ ...formData, dob: e.target.value })} />
    </div>

    {/* gender */}
    <div className=" flex flex-col mb-[1rem] " >
      <SelectComponent
        options={GENDER_OPTIONS}
        onChange={(value) => setFormData({ ...formData, gender: value })}
        label="Gender"
      />
    </div>

    {/* school name */}
    <div className=" flex flex-col mb-[1rem] " >
      <label >School name <span className=' text-red-600' >*</span></label>
      <input type="text" placeholder="Enter school name" onChange={(e) => setFormData({ ...formData, schoolname: e.target.value })} />
    </div>
    {/*  school province location */}
    <div className=" flex flex-col mb-[1rem] " >
      <SelectComponent
        options={CollegeProvince}
        onChange={(value) => setFormData({ ...formData, province: value })}
        label="School province"
      />
    </div>
    {/* physical address */}
    <div className=" flex flex-col mb-[1rem] " >
      <label >School street address <span className=' text-red-600' >*</span></label>
      <input type="text" placeholder="Enter school address" onChange={(e) => setFormData({ ...formData, schooladdress: e.target.value })} />
    </div>

    {/* average grade */}
    <div className=" flex flex-col mb-[1rem] " >
      <label >Your average grades <span className=' text-red-600' >*</span></label>
      <input type="number" min={0} max={100} placeholder="Enter average grades" onChange={(e) => setFormData({ ...formData, gradeavg: e.target.value })} />
    </div>

  </div>
}


export default StudentForm;