import { useState } from "react";
import SelectComponent from "../../../components/selectComponent";
import { GENDER_OPTIONS } from "../../../utils/data/constants";
import Navbar from "../../../components/navbar";

export const SUBJECT_CONFIDENT_OPTIONS = [
  { value: 'not confident', label: 'Not Confident' },
  { value: 'somewhat confident', label: 'Somewhat Confident' },
  { value: 'moderately confident', label: 'Moderately Confident' },
  { value: 'confident', label: 'Confident' },
  { value: 'very confident', label: 'Very Confident' },
];

const PostTutorialSurvey: React.FC = () => {
  const [formData, setFormData] = useState({});

  function handleFormSubmit() {
    try {
      console.log(formData);
    }
    catch (err: any) {
      console.log(err);
    }
  }

  return <div className=" px-[1rem] pb-[3rem] " >

    <Navbar />

    {/*  */}
    <div className=" mx-auto max-w-[800px] pt-[2rem] " >
      <div className=" text-3xl mb-[1rem] " >
        Post Tutorial Survey
      </div>
      {/*  */}
      <div className="  border-t-[0.1rem] pt-[1rem] " >
        {/*  */}
        <div className="bg-el_green text-2xl py-[0.3rem] capitalize font-bold text-center text-white mb-[1rem]  " >
          personal information
        </div>

        {/* name and dob */}
        <div className=" flex flex-row items-center " >
          <div className=" divform w-6/12 mr-[0.4rem] " >
            <label >Name</label>
            <input placeholder="Enter name" onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
          </div>

          <div className=" divform w-6/12 ml-[0.4rem]" >
            <label >Date of birth</label>
            <input type="date" placeholder="Enter date of birth" onChange={(e) => setFormData({ ...formData, dob: e.target.value })} />
          </div>
        </div>

        {/* gender and age  */}
        <div className=" flex flex-row" >
          <div className=" w-4/12 divform mr-[0.4rem]  " >
            <label >Age</label>
            <input type="number" min={1} placeholder="Enter age" onChange={(e) => setFormData({ ...formData, age: e.target.value })} />
          </div>
          <div className=" flex flex-col w-8/12 ml-[0.4rem] " >
            <SelectComponent
              options={GENDER_OPTIONS}
              onChange={(value) => setFormData({ ...formData, gender: value })}
              label="Gender"
            />
          </div>
        </div>

        {/* avg grade and age  */}
        <div className=" flex flex-row" >
          <div className=" divform w-4/12 mr-[0.4rem] " >
            <label >Average grade</label>
            <input type="number" min={0} max={100} placeholder="Enter grades percentage" onChange={(e) => setFormData({ ...formData, avgGrades: e.target.value })} />
          </div>
          <div className=" divform w-8/12 ml-[0.4rem] " >
            <label >Subject</label>
            <input placeholder="Enter subject" onChange={(e) => setFormData({ ...formData, subject: e.target.value })} />
          </div>
        </div>
      </div>

      {/* Part B */}
      <div className=" border-t-[0.1rem] pt-[1rem] mt-[3rem] " >

        <div className=" flex flex-col pb-[2rem] " >
          <span className=" text-2xl font-bold text-white bg-el_green text-center py-[0.3rem] " >Instructions</span>
          <span className=" text-[0.9rem] px-[1rem] py-[0.5rem] " >
            Please complete the following pre-tutorial survey. Your responses are valuable and should reflect your
            true perspectives and expectations.
          </span>
        </div>

        <div className=" flex flex-col mb-[1rem] " >
          <SelectComponent
            options={SUBJECT_CONFIDENT_OPTIONS}
            onChange={(value) => setFormData({ ...formData, subjectConfidence: value })}
            label="How confident do you feel about the subject we are going to cover ?"
          />
        </div>

        <div className=" divform " >
          <label >What specific topics do you feel least confident about?</label>
          <textarea placeholder="What specific topics do you feel least confident about?"
            className=" w-full"
            onChange={(e) => setFormData({ ...formData, topicConfident: e.target.value })} />
        </div>

        <div className=" divform " >
          <label >What do you hope to achieve from this tutorial?</label>
          <textarea placeholder="What do you hope to achieve from this tutorial?"
            className=" w-full"
            onChange={(e) => setFormData({ ...formData, achieveInTut: e.target.value })} />
        </div>

        <div className=" divform " >
          <label >Please describe your expectations for your tutor regarding their communication, feedback, and overall teaching approach</label>
          <textarea placeholder="Please describe your expectations for your tutor regarding their communication, feedback, and overall teaching approach."
            className=" w-full"
            onChange={(e) => setFormData({ ...formData, expectations: e.target.value })} />
        </div>

        <div className=" divform " >
          <label >Any suggestions or comments?</label>
          <textarea placeholder="Any suggestions or comments?"
            className=" w-full"
            onChange={(e) => setFormData({ ...formData, suggesttions: e.target.value })} />
        </div>

      </div>

      {/*  */}
      <button className=" w-full mt-[3rem] " onChange={(e) => handleFormSubmit()} >
        Submit
      </button>
    </div>
  </div>
}

export default PostTutorialSurvey;