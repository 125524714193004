import { FormComponentProps } from "../interfaces";

const ResoucesForm: React.FC<FormComponentProps> = ({ formData, setFormData }) => {

  return <div className=" divform " >
    <div className=" divform flex flex-col" >
      <label >Number of teachers with ICT skills </label>
      <input placeholder="Enter number of teacher with ICT skills"
        className=" w-full"
        type="number"
        min={0}
        onChange={(e) => setFormData({ ...formData, "numberTeachICTSkill": e.target.value })} />
    </div>
    <div className=" divform flex flex-col" >
      <label >Name of ICT lab lecturer at college </label>
      <input placeholder="Enter name of ICT lab lecturer"
        className=" w-full"
        onChange={(e) => setFormData({ ...formData, "nameICTLabLecturer": e.target.value })} />
    </div>

    <div className=" divform flex flex-col" >
      <label >Phone number of ICT lab lecturer at college </label>
      <input placeholder="Enter phone number of ICT lab lecturer"
        className=" w-full"
        onChange={(e) => setFormData({ ...formData, "phoneNumberICTLabLecturer": e.target.value })} />
    </div>

    <div className=" divform flex flex-col" >
      <label >Email of ICT lab lecturer at college </label>
      <input placeholder="Enter email of ICT lab lecturer"
        className=" w-full"
        onChange={(e) => setFormData({ ...formData, "emailICTLabLecturer": e.target.value })} />
    </div>
  </div>
}

export default ResoucesForm;