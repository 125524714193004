
import { supabase } from "./supabase";

export async function insert(formData, tableName) {
  try {
    const { data, error } = await supabase
      .from(tableName)
      .insert(formData)
      .select()
    // 
    console.log(error);
    if (error) {
      return { status: false, message: error.message, data: data }
    }
    return { status: true, message: "success", data: data }
  }
  catch (err) {
    return { status: false, message: err.message, data: null }
  }
}

export async function get(tableName) {
  try {
    const { data, error } = await supabase
      .from(tableName)
      .select()
    if (error) {
      return { status: false, message: error.message, data: null }
    }
    return { status: true, message: "success", data: data }
  }
  catch (err) {
    return { status: true, message: err.message, data: null }
  }
}