import React from 'react';
import { ROUTER_NAME_COURSES, ROUTER_NAME_POST_TUTORIAL_SURVEY, ROUTER_NAME_REGISTER_STUDENT_QR_CODE, ROUTER_NAME_SURVEY_PAGE } from '../utils/browserRouter/routesNames';
import HamburgerMenu from './hamburgerMenu';

const Navbar: React.FC = () => {
  return (
    <nav className=" shadow-md w-full top-0">
      <div className=" px-[1rem] ">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            {/* Logo */}
            <div className="flex-shrink-0">
              <a href="/" className="text-2xl ">Ecolabs Learning</a>
            </div>
            {/* Menu */}
            <div className="hidden md:flex space-x-4 ml-10">
              <a href="/" className=" hover:text-blue-600 px-3 py-2 rounded-md font-medium">
                Home
              </a>
              {/* <a href={ROUTER_NAME_SURVEY_PAGE} className=" hover:text-blue-600 px-3 py-2 rounded-md font-medium">
                Survey
              </a> */}
              {/* <a href={ROUTER_NAME_COURSES} className=" hover:text-blue-600 px-3 py-2 rounded-md font-medium">
                Courses
              </a> */}
              <a href={ROUTER_NAME_REGISTER_STUDENT_QR_CODE} className=" hover:text-blue-600 px-3 py-2 rounded-md font-medium">
                Register Student
              </a>
              {/* <a href={ROUTER_NAME_POST_TUTORIAL_SURVEY} className=" hover:text-blue-600 px-3 py-2 rounded-md font-medium">
                Post Tut Survey
              </a> */}
            </div>
          </div>

          {/*  */}
          <div className=' flex md:hidden '>
            <HamburgerMenu />
          </div>
          {/*  */}


          {/* Right Side - Add more elements like buttons or login */}
          {/* <div className="flex items-center space-x-4">
            <a href="/login" className="text-gray-600 hover:text-blue-600 px-3 py-2 rounded-md   font-medium">
              Login
            </a>
            <a href="/signup" className="bg-blue-600 px-4 py-2 rounded-md   font-medium hover:bg-blue-700">
              Sign Up
            </a>
          </div> */}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
