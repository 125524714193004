import { useEffect } from 'react'
// import { useNavigate } from 'react-router-dom';

function ViewSurveys() {
  // const navgiate = useNavigate();

  useEffect(() => {
  }, []);


  return (<div className='admin' >
    <h2 >View Surveys</h2>
  </div>);
}

export default ViewSurveys;