import React from "react";


const RoutingErrorPage: React.FC = () => {

  return <div className=" h-screen w-full text-center flex flex-col justify-center" >
    Error
  </div>
}


export default RoutingErrorPage;