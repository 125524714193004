
export const ROUTER_NAME_HOME_PAGE: string = "/";

// quiz
export const ROUTER_NAME_COURSES: string = "/courses";
export const ROUTER_NAME_COURSE: string = "/courses/:id";
export const ROUTER_NAME_QUIZ_PAGE: string = "/courses/quiz";

// general
export const ROUTER_NAME_SURVEY_PAGE: string = "/survey";
export const ROUTER_NAME_REGISTER_STUDENT_QR_CODE: string = "/studentregistration";
export const ROUTER_NAME_POST_TUTORIAL_SURVEY: string = "/posttutorialsurvey";


// admins
export const ROUTER_NAME_ADMIN_PAGE: string = "/admin";
export const ROUTER_NAME_LOGIN_ADMIN_PAGE: string = "/adminlogin";
export const ROUTER_NAME_ADD_ADMIN_PAGE: string = "/admin/addadmin";
export const ROUTER_NAME_VIEW_SURVEYS_PAGE: string = "/admin/surveys";
