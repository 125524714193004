// validate phone number
export const validateSAPhoneNumber = (text: string) => {
  if (text === undefined || text === null) {
    return 'Phone number is required';
  }
  else if (text.length > 0) {
    var number = text;
    var trimmed = number.replace(/\s/g, '');
    var regex = /^0(6|7|8){1}[0-9]{8}$/;
    if (regex.test(trimmed) === true) return 1;

    return "Invalid Phone Number";
  }
  else {
    return "No Phone Number provided";
  }
}

// VLAIDATE EMAIL
export const validateEmail = (text: string) => {
  if (text === undefined || text === null) {
    return 'Email is required';
  } else if (text.length > 0 && /@/.test(text) && /[.]/.test(text)) {
    // 
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (emailPattern.test(text)) return 1;
    // 
    return 'Invalid Email';
  }
  else {
    return 'Invalid Email';
  }
}

// validate password
export function validatePassword(password: string): boolean | string {
  const minLength = 8; // More than 6 characters means minimum length is 6
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
  const hasUpperCase = /[A-Z]/.test(password);
  const hasNumber = /[0-9]/.test(password);

  if (password === undefined || password === null || password.length < minLength) {
    return "password is less than 6 chars";
  }
  if (!hasSpecialChar) {
    return "no special character in the password";
  }
  if (!hasUpperCase) {
    return "no atleast one uppercase letter in the password";
  }
  if (!hasNumber) {
    return "no atleast one number in the password";
  }
  return true;
}

// generate strong password
export function generateStrongPassword(length: number = 16): string {
  const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
  const numbers = '0123456789';
  const specialChars = '!@#$%^&*()_+[]{}|;:,.<>?';

  const allChars = uppercaseChars + lowercaseChars + numbers + specialChars;
  let password = '';

  // add chars
  password += uppercaseChars[Math.floor(Math.random() * uppercaseChars.length)];
  password += lowercaseChars[Math.floor(Math.random() * lowercaseChars.length)];
  password += numbers[Math.floor(Math.random() * numbers.length)];
  password += specialChars[Math.floor(Math.random() * specialChars.length)];

  // fill password
  for (let i = password.length; i < length; i++) {
    password += allChars[Math.floor(Math.random() * allChars.length)];
  }

  // shuffle the password
  password = password.split('').sort(() => Math.random() - 0.5).join('');

  return password;
}

export function generateStrongPasswordV1(length: number = 16): string {
  const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const lowercase = 'abcdefghijklmnopqrstuvwxyz';
  const numbers = '0123456789';
  const specialChars = '!@#$%^&*()_+~`|}{[]:;?><,./-=';

  const allChars = uppercase + lowercase + numbers + specialChars;

  let password = '';

  // Ensure at least one character from each category
  password += uppercase[Math.floor(Math.random() * uppercase.length)];
  password += lowercase[Math.floor(Math.random() * lowercase.length)];
  password += numbers[Math.floor(Math.random() * numbers.length)];
  password += specialChars[Math.floor(Math.random() * specialChars.length)];

  // Fill the rest of the password
  for (let i = password.length; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * allChars.length);
    password += allChars[randomIndex];
  }

  // Shuffle the password
  return password.split('').sort(() => Math.random() - 0.5).join('');
}

// copy text to clip board
export const copyTextClipboard = async (text: string, msg: string) => {
  try {
    await navigator.clipboard.writeText(text);
    alert(`${msg} copied to clipboard`);
  } catch (err) {
    alert(`failed to copy text to clipboard: ${err}`);
    // console.error("Failed to copy: ", err);
  }
};