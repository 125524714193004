import React, { useState } from 'react';
import { ROUTER_NAME_COURSES, ROUTER_NAME_HOME_PAGE, ROUTER_NAME_REGISTER_STUDENT_QR_CODE, ROUTER_NAME_SURVEY_PAGE } from '../utils/browserRouter/routesNames';

const HamburgerMenu: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="">
      {/* Hamburger Icon */}
      <button
        className=" bg-el_darkgrey px-[1rem] py-[0.7rem] focus:outline-none"
        onClick={toggleMenu}
        aria-label="Toggle Menu"
      >
        {isOpen ? "Close" : "Menu"}
      </button>

      {/* Dropdown Menu */}
      <div className={` pl-[1.5rem] py-[1rem] flex flex-col absolute w-full bottom-0 right-0 left-0 top-16 bg-el_darkgrey rounded-lg shadow-lg transition-transform ${isOpen ? 'scale-100' : 'scale-0'}`}>
        <a className=" w-full px-4 py-2 hover:bg-gray-200" href={ROUTER_NAME_HOME_PAGE}>Home</a>
        {/* <a className="px-4 py-2 hover:bg-gray-200" href={ROUTER_NAME_SURVEY_PAGE} >Survey</a> */}
        {/* <a className="px-4 py-2 hover:bg-gray-200" href={ROUTER_NAME_COURSES}>Courses</a> */}
        <a className="px-4 py-2 hover:bg-gray-200" href={ROUTER_NAME_REGISTER_STUDENT_QR_CODE}>Student Registration</a>
      </div>
    </div>
  );
};

export default HamburgerMenu;
