
interface StudentFormProps {
  setFormData: (v: any) => void;
  formData: any;
}

const GENDER_OPTIONS = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
  { value: 'other', label: 'Other' },
  { value: 'unknown', label: 'Prefer not to say' },
];

// 
const GuardianForm: React.FC<StudentFormProps> = ({ setFormData, formData }) => {

  return <div className=" py-[1rem] " >

    {/* <div className=" text-3xl py-[1rem] " >
      Guardian Information
    </div> */}
    {/* */}
    <div className=" flex flex-col mb-[1rem] " >
      <label >Guardian Name <span className=' text-red-600' >*</span></label>
      <input placeholder="Enter guardian name" onChange={(e) => setFormData({ ...formData, gname: e.target.value })} />
    </div>

    {/* G */}
    <div className=" flex flex-col mb-[1rem] " >
      <label >Guardian Surname
        <span className=' text-red-600' > *</span>
      </label>
      <input placeholder="Enter guardian surname" onChange={(e) => setFormData({ ...formData, gsurname: e.target.value })} />
    </div>

    <div className=" flex flex-col mb-[1rem] " >
      <label >Guardian Phone number
        <span className=' text-red-600' > *</span>
      </label>
      <input type="tel" placeholder="Enter guardian phone number" onChange={(e) => setFormData({ ...formData, gphonenumber: e.target.value })} />
    </div>
  </div>
}

export default GuardianForm;