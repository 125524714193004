import RadioButton from "../../../../components/radioBtnComponent";
import { FormComponentProps } from "../interfaces";


const SuggestCommentForm: React.FC<FormComponentProps> = ({ formData, setFormData }) => {

  return <div className="" >
    <div className=" divform flex flex-col">
      <label >Is the lab still operational *</label>
      <div className=" ">
        <RadioButton
          label="Yes"
          value="yes"
          isSelected={formData?.labOpoerational === "yes"}
          onChange={(e) => setFormData({ ...formData, "labOpoerational": e })}
        />
        <RadioButton
          label="No"
          value="no"
          isSelected={formData?.labOpoerational === "no"}
          onChange={(e) => setFormData({ ...formData, "labOpoerational": e })}
        />
      </div>
    </div>

    <div className=" divform flex flex-col" >
      <label >Additional comments or suggestions *</label>
      <textarea placeholder="Enter number of laptop at college"
        className=" w-full"
        onChange={(e) => setFormData({ ...formData, "commentSuggestions": e.target.value })} />
    </div>
  </div>
}

export default SuggestCommentForm;