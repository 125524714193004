import SelectComponent from "../../../../components/selectComponent";
import { FormComponentProps } from "../interfaces";


const FairGoodRating = [
  { value: 'good', label: 'Good' },
  { value: 'fair', label: 'Fair' },
  { value: 'poor', label: 'Poor' },
  { value: 'terrible', label: 'Terrible' },
];


const RateQualityForm: React.FC<FormComponentProps> = ({ formData, setFormData }) => {

  // 
  return <div className="" >
    <div className="divform ">
      <SelectComponent
        options={FairGoodRating}
        onChange={(value) => setFormData({ ...formData, "networking": value })}
        label="Networking (Router & Internet Access)"
      />
    </div>

    <div className="divform ">
      <SelectComponent
        options={FairGoodRating}
        onChange={(value) => setFormData({ ...formData, "computerLaptop": value })}
        label="Computer/Laptops, Chargers, & power cables"
      />
    </div>

    <div className="divform">
      <SelectComponent
        options={FairGoodRating}
        onChange={(value) => setFormData({ ...formData, "projectors": value })}
        label="Projectors, Smart TV, & Smart boards"
      />
    </div>

    <div className="divform">
      <SelectComponent
        options={FairGoodRating}
        onChange={(value) => setFormData({ ...formData, "infrasElectricity": value })}
        label="Infrastructure & Electricity"
      />
    </div>

    <div className="divform">
      <SelectComponent
        options={FairGoodRating}
        onChange={(value) => setFormData({ ...formData, "cameraSafety": value })}
        label="Camera & Safety"
      />
    </div>

    <div className="divform">
      <SelectComponent
        options={FairGoodRating}
        onChange={(value) => setFormData({ ...formData, "airConditioning": value })}
        label="Air Conditioning"
      />
    </div>
  </div>
}

export default RateQualityForm;