import React, { useState } from 'react';
import { COURSE1_QUIZ1 } from '../../utils/data/course1_quiz';

const QuizPage: React.FC = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState<string[]>(Array(COURSE1_QUIZ1.length).fill(''));
  const [score, setScore] = useState<number | null>(null);
  const [quizCompleted, setQuizCompleted] = useState(false);

  const currentQuestion = COURSE1_QUIZ1[currentQuestionIndex];

  // Handle when the user selects an option
  const handleOptionChange = (option: string) => {
    const updatedOptions = [...selectedOptions];
    updatedOptions[currentQuestionIndex] = option;
    setSelectedOptions(updatedOptions);
  };

  // Handle going to the next question
  const handleNext = () => {
    if (currentQuestionIndex + 1 < COURSE1_QUIZ1.length) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
    else {
      alert("Please select an option")
    }
  };

  // Handle going to the previous question
  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
    else {
      alert("Please select an option")
    }
  };

  // Handle submission of the quiz
  const handleSubmit = () => {
    let calculatedScore = 0;
    selectedOptions.forEach((option, index) => {
      if (option === COURSE1_QUIZ1[index].answer) {
        calculatedScore++;
      }
    });
    setScore(calculatedScore);
    setQuizCompleted(true);
  };

  // Handle reset
  const handleReset = () => {
    setCurrentQuestionIndex(0);
    setSelectedOptions(Array(COURSE1_QUIZ1.length).fill(''));
    setScore(null);
    setQuizCompleted(false);
  };

  return (
    <div style={{ padding: '20px', maxWidth: '600px', margin: '0 auto' }}>
      <div className=' text-3xl mb-[2rem] '>Digital Literacy Quiz</div>
      {quizCompleted ? (
        <div className=' my-[2rem] '>
          <div className=' text-center font-bold mt-[2rem] '>Quiz Completed!</div>
          <p className=' my-[2rem] ' >Your score: {score} / {COURSE1_QUIZ1.length}</p>
          <button className=' w-full mt-[3rem] ' onClick={handleReset}>Retry Quiz</button>
        </div>
      ) : (
        <div className='' >
          <div className=' mb-[2rem] ' >
            {/* <div>{currentQuestion.section}</div> */}
            <div className=' w-full bg-el_green text-lg py-[0.3rem] text-white mb-[1rem] pl-[0.2rem] '>{currentQuestion.question}</div>
          </div>
          <div className=''>
            {currentQuestion.options.map((option, index) => (
              <div className=' mb-[0.7rem]  ' key={index}>
                <label className=' cursor-pointer hover:bg-el_darkgrey hover:text-white px-[0.6rem] focus:text-white focus:bg-el_darkgrey ' >
                  <input
                    className='mr-[1rem] cursor-pointer '
                    type="radio"
                    value={option}
                    checked={selectedOptions[currentQuestionIndex] === option}
                    onChange={() => handleOptionChange(option)}
                  />
                  {option}
                </label>
              </div>
            ))}
          </div>

          <div className=' mt-[3rem] flex flex-row justify-between '>
            <button
              className=' w-5/12'
              onClick={handlePrevious}
              disabled={currentQuestionIndex === 0}
              style={{ marginRight: '10px' }}
            >
              Previous
            </button>
            {currentQuestionIndex + 1 === COURSE1_QUIZ1.length ? (
              <button className=' cursor-pointer w-full' onClick={handleSubmit} disabled={!selectedOptions[currentQuestionIndex]}>
                Submit Quiz
              </button>
            ) : (
              <button
                className=' w-5/12  '
                onClick={handleNext}
                disabled={!selectedOptions[currentQuestionIndex]}
              >
                Next
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default QuizPage;
