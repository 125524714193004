import React from 'react';

interface VideoPlayerProps {
  src: string;
  controls?: boolean;
  width?: string;
  height?: string;
  autoPlay?: boolean;
  loop?: boolean;
  muted?: boolean;
  poster?: string;
}

const VideoPlayerComponent: React.FC<VideoPlayerProps> = ({
  src,
  controls = true,
  width = '100%',
  height = 'auto',
  autoPlay = false,
  loop = false,
  muted = false,
  poster,
}) => {
  return (
    <video
      className=' rounded-[0.9rem] '
      src={src}
      controls={controls}
      width={width}
      height={height}
      autoPlay={autoPlay}
      loop={loop}
      muted={muted}
      poster={poster}
    >
      Your browser does not support the video tag.
    </video>
  );
};

export default VideoPlayerComponent;
