import './App.css';
import { useNavigate } from 'react-router-dom';
import Navbar from './components/navbar';

function App() {
  const navigate = useNavigate();

  return (<div className='parent' >
    <Navbar />

    <div className=' text-2xl mt-[2rem] text-center ' >Ecolabs Learning Platform</div>
  </div>);
}

export default App;
