import { useState } from "react";
import { SUBJECTS } from "../../../../utils/data/constants";

interface StudentFormProps {
  setFormData: (v: any) => void;
  formData: any;
}

// 
const SubjectForm: React.FC<StudentFormProps> = ({ setFormData, formData }) => {
  const [selectedSubjects, setSelectedSubjects] = useState<string[]>([]);

  const handleCheckboxChange = (subject: string) => {
    // let selected: string[] = selectedSubjects;
    // selected = selected.includes(subject) ? selected.filter((i: string) => i !== subject) : [...selected, subject]
    // setSelectedSubjects(selected);

    setSelectedSubjects((prevSelected) =>
      prevSelected.includes(subject)
        ? prevSelected.filter((item) => item !== subject)
        : [...prevSelected, subject]
    );

    // set data into form variable
    // console.log(selected)
    // console.log(selectedSubjects)
    setFormData({ ...formData, subjects: selectedSubjects });
  };

  return <div className="" >

    <div className=" text-xl mb-[1.5rem] " >Select your subject(s) </div>

    {SUBJECTS && SUBJECTS.length > 0 ? (
      SUBJECTS.map((subject: any, idx: number) => (
        <div className="flex flex-row items-center mb-[0.3rem]" key={idx}>
          <input
            className="px-[0.5rem]"
            type="checkbox"
            name="items"
            value={subject.subject}
            onChange={() => handleCheckboxChange(subject.subject)}
            checked={selectedSubjects.includes(subject.subject)}
          />
          <span
            className={`px-[1rem] text-[1.1rem] ${selectedSubjects.includes(subject.subject) ? 'underline' : ''
              }`}
          >
            {subject.subject}
          </span>
        </div>
      ))
    ) : (
      <div>no subjects</div>
    )}

  </div>
}

export default SubjectForm;