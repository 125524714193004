import React from "react";

interface LoadingProps {
  message: string | null;
}

const Loading: React.FC<LoadingProps> = ({ message }) => {
  return (
    <div className="fixed inset-0 flex flex-col items-center justify-center bg-white z-50"
      style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }} >
      <div className="animate-spin rounded-full border-t-2 border-b-2 border-gray-900 h-12 w-12 mb-4"></div>
      <p className="text-gray-700">{message}</p>
    </div>
  );
};

export default Loading;