import {
  createBrowserRouter
} from "react-router-dom";
import App from "../../App";
import ErrorPage from "../../components/routingErrorPage";
import { ROUTER_NAME_ADD_ADMIN_PAGE, ROUTER_NAME_ADMIN_PAGE, ROUTER_NAME_COURSE, ROUTER_NAME_COURSES, ROUTER_NAME_HOME_PAGE, ROUTER_NAME_LOGIN_ADMIN_PAGE, ROUTER_NAME_POST_TUTORIAL_SURVEY, ROUTER_NAME_QUIZ_PAGE, ROUTER_NAME_REGISTER_STUDENT_QR_CODE, ROUTER_NAME_SURVEY_PAGE, ROUTER_NAME_VIEW_SURVEYS_PAGE } from "./routesNames";
import SurveyPage from "../../modules/users/survey";
import Admin from "../../modules/admin/admin";
import AdminLogin from "../../modules/admin/auth/login";
import CoursesHome from "../../modules/courses";
import AddAdmin from "../../modules/admin/auth/addAdmin";
import ViewSurveys from "../../modules/admin/pages/viewSurveys";
import Quiz from "../../modules/courses/quiz";
import RegisterStudentQRCode from "../../modules/users/registerQr.tsx";
import PostTutorialSurvey from "../../modules/users/rateTutor";
import Course from "../../modules/courses/course";



const router = createBrowserRouter([
  { path: ROUTER_NAME_HOME_PAGE, element: <App />, errorElement: <ErrorPage /> },
  { path: ROUTER_NAME_SURVEY_PAGE, element: <SurveyPage /> },
  { path: ROUTER_NAME_ADMIN_PAGE, element: <Admin /> },
  { path: ROUTER_NAME_LOGIN_ADMIN_PAGE, element: <AdminLogin /> },
  { path: ROUTER_NAME_COURSES, element: <CoursesHome /> },
  { path: "/courses/:id", element: <Course /> }, // 
  // { path: ROUTER_NAME_COURSE, element: <Course /> }, // "/courses/:id"
  { path: ROUTER_NAME_ADD_ADMIN_PAGE, element: <AddAdmin /> },
  { path: ROUTER_NAME_VIEW_SURVEYS_PAGE, element: <ViewSurveys /> },
  { path: ROUTER_NAME_QUIZ_PAGE, element: <Quiz /> },
  { path: ROUTER_NAME_REGISTER_STUDENT_QR_CODE, element: <RegisterStudentQRCode /> },
  { path: ROUTER_NAME_POST_TUTORIAL_SURVEY, element: <PostTutorialSurvey /> },
]);

export default router;