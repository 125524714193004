import RadioButton from "../../../../components/radioBtnComponent"
import { FormComponentProps } from "../interfaces"



const ComplaintsForm: React.FC<FormComponentProps> = ({ formData, setFormData }) => {

  return <div className="" >
    <div className=" divform flex flex-col">
      <label >Have there been any complaints logged about the lab *</label>
      <div>
        <RadioButton
          label="Yes"
          value="yes"
          isSelected={formData?.complainsLogged === "yes"}
          onChange={(e) => setFormData({ ...formData, "complainsLogged": e })}
        />
        <RadioButton
          label="No"
          value="no"
          isSelected={formData?.complainsLogged === "no"}
          onChange={(e) => setFormData({ ...formData, "complainsLogged": e })}
        />
      </div>
    </div>
    {formData?.complainsLogged === "yes" &&
      <div className=" divform flex flex-col" >
        <label >Number of number with access to lab *</label>
        <textarea placeholder="Enter enter description of your complain"
          className=" w-full"
          onChange={(e) => setFormData({ ...formData, "complainsDescription": e.target.value })} />
      </div>
    }
  </div>
}

export default ComplaintsForm;