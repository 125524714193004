import { useState } from "react";
import SelectComponent from "../../../../components/selectComponent";
import { FormComponentProps } from "../interfaces";
import { CollegeProvince } from "../../../../utils/data/constants";

const CollegeOptions = [
  { value: 'option1', label: 'Option 1' },
  { value: 'option2', label: 'Option 2' },
  { value: 'option3', label: 'Option 3' },
];

const CollegeLocation = [
  { value: 'township', label: 'Township' },
  { value: 'rural', label: 'Rural' },
  { value: 'urban', label: 'Urban' },
  { value: 'suburb', label: 'Suburb' },
];


const CollegeInfoForm: React.FC<FormComponentProps> = ({ formData, setFormData }) => {
  const [errors, setErrors] = useState({});

  return <div className="" >
    <div className="divform">
      <SelectComponent
        options={CollegeOptions}
        onChange={(value) => setFormData({ ...formData, "collegeType": value })}
        label="Choose a college type"
      />
    </div>
    {/* college location */}
    <div className="divform">
      <SelectComponent
        options={CollegeLocation}
        onChange={(value) => setFormData({ ...formData, "collegeLocation": value })}
        label="Choose a college location"
      />
    </div>
    {/* collect address */}
    <div className=" divform flex flex-col" >
      <label >Collect Address</label>
      <input placeholder="Enter collect location"
        className=" w-full"
        onChange={(e) => setFormData({ ...formData, "collegeAddress": e.target.value })} />
    </div>
    {/* collect province */}
    <div className="divform">
      <SelectComponent
        options={CollegeProvince}
        onChange={(value) => setFormData({ ...formData, "collegeProvince": value })}
        label="Choose a college province"
      />
    </div>
  </div>
}


export default CollegeInfoForm;