
// 
import { validateSAPhoneNumber } from "./routines"

// 
// 
// 
function validateStudentForm(data: any): { message: string, success: boolean } {
  try {
    // the form object
    if (data && Object.keys(data).length > 0) {
      // name and surname
      if (data?.name && data?.surname && data?.name.length > 1 && data?.surname.length > 1) {
        // gaurdian phone number
        const val_pn: string | number = validateSAPhoneNumber(data?.gphonenumber);
        if (data?.gphonenumber && val_pn === 1) {
          // gender
          if (data?.gender && data?.gender.length > 0) {
            // DOB
            if (data?.dob && data?.dob.length > 0) {
              //  school information
              if (data?.schoolname && data?.province && data?.schooladdress) {
                // guardian information
                if (data?.gname && data?.gsurname) {
                  // subjects
                  if (data?.subjects && data?.subjects.length > 0) {
                    return { message: "", success: true }
                  }
                  else {
                    return { message: "Please Select Atleast One Subject", success: false }
                  }
                }
                else {
                  return { message: "Please Complete Guardian Information", success: false }
                }
              }
              else {
                return { message: "Please Complete School Information", success: false }
              }
            }
            else {
              return { message: "Please Provide Date of Birth", success: false }
            }
          }
          else {
            return { message: "Please Provide Select A Gender", success: false }
          }
        }
        else {
          return { message: "Please Provide Correct Guardian Phone Number", success: false }
        }
      }
      else {
        return { message: "Please  Provide Student Name And Surname", success: false }
      }
    }
    else {
      return { message: "Invalid Form Provided", success: false }
    }
  }
  catch (err: any) {
    return { message: err.message, success: false }
  }
}


export default validateStudentForm;