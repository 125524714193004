import SelectComponent from "../../../../components/selectComponent";
import { FormComponentProps } from "../interfaces";


const RateMaintenanceSatisfaction = [
  { label: 'Very Unsatisfied', value: '1' },
  { label: 'Unsatisfied', value: '2' },
  { label: 'Neutral', value: '3' },
  { label: 'Satisfied', value: '4' },
  { label: 'Very Satisfied', value: '5' },
];

const RateLogMaintenanceFixed = [
  { value: 'very slow', label: 'Very Slow' },
  { value: 'slow', label: 'Slow' },
  { value: 'fast', label: 'Fast' },
  { value: 'very fast', label: 'Very Fast' },
];


const MaintenanceForm: React.FC<FormComponentProps> = ({ formData, setFormData }) => {

  return <div className="" >
    <div className=" divform ">
      <SelectComponent
        options={RateMaintenanceSatisfaction}
        onChange={(value) => setFormData({ ...formData, "rateMaintainSatisfaction": value })}
        label="Rate the overall satisfaction with our maintenance service"
      />
    </div>

    <div className=" divform">
      <SelectComponent
        options={RateLogMaintenanceFixed}
        onChange={(value) => setFormData({ ...formData, "rateMaintenanceFixed": value })}
        label="Rate the speed at which logged maintenance problems are resolved"
      />
    </div>
  </div>
}

export default MaintenanceForm;