import React, { useState } from "react";
import StudentForm from "./component/studentForm";
import GuardianForm from "./component/guardianForm";
import SubjectForm from './component/subjectForm';
import Navbar from "../../../components/navbar";
import { insert } from "../../../utils/supabase/ops";
import Loading from "../../../components/loading";
import validateStudentForm from "../../../utils/routines/validateStudentForm";
import { useNavigate } from "react-router-dom";
import { ROUTER_NAME_HOME_PAGE } from "../../../utils/browserRouter/routesNames";

const RegisterStudentQRCode: React.FC = () => {
  const [formData, setFormData] = useState<any>({});
  const [formToShow, setFormToShow] = useState<number>(1);
  const [loadingStatus, setLoadingStatus] = useState<{ loading: boolean, message: string }>({ loading: false, message: "... Loading" });
  const navigate = useNavigate();

  // 
  async function handleFormSubmit(e: React.FormEvent<HTMLElement>) {
    try {
      e.preventDefault();
      setLoadingStatus({ ...loadingStatus, loading: true });
      // validate form
      const validForm = validateStudentForm(formData);
      if (validForm.success === true) {
        // add back database api codes
        if (formData && Object.keys(formData).length > 0) {
          const data = {
            name: formData?.name,  //
            surname: formData?.surname, //
            gender: formData?.gender, //
            dob: formData?.dob, //
            school_name: formData?.schoolname, //
            province: formData?.province, //
            school_address: formData?.schooladdress, //
            grades_average: formData?.gradeavg, //
            gaurdian_name: formData?.gname, // 
            gaurdian_surname: formData?.gsurname, //
            gaurdian_phone: formData?.gphonenumber, //
            subjects: formData?.subjects, // 
          }
          const res = await insert(data, "students");
          if (res && res.status === true) {
            alert("Student Information Recorded");
            setLoadingStatus({ ...loadingStatus, loading: false });
            // 
            setFormData({});
            navigate(ROUTER_NAME_HOME_PAGE);
          }
          else {
            alert(`insert - ${res.message}`);
            setLoadingStatus({ ...loadingStatus, loading: false });
          }
        }
        else {
          alert("Form Not Filled-In");
          setLoadingStatus({ ...loadingStatus, loading: false });
        }
      }
      else {
        alert(`form validation - ${validForm?.message}`);
        setLoadingStatus({ ...loadingStatus, loading: false });
      }
    }
    catch (err: any) {
      alert(`error - ${err.message}`);
      setLoadingStatus({ ...loadingStatus, loading: false });
    }
  }

  function handlePrevBtn() {
    try {
      // 
      setFormToShow(formToShow - 1);
    }
    catch (err: any) {
      console.log(err);
    }
  }

  function handleNextBtn() {
    try {
      // 
      if (formToShow === 1) {
        // validate student information
        setFormToShow(formToShow + 1);
      }
      else if (formToShow === 2) {
        // validate guardian information
        setFormToShow(formToShow + 1);
      }
    }
    catch (err: any) {
      console.log(err);
    }
  }

  // 
  return <div className=" pt-[1rem] pb-[2rem] lg:px-0 " >

    <Navbar />

    <div className="mx-auto max-w-[800px] pt-[2rem] px-[1rem]  " >
      {/*  */}
      <div className=" text-3xl mb-[2rem] " >
        {formToShow === 1 ?
          "Student Registration" :
          formToShow === 2 ?
            "Student Guardian Information" :
            "Student Subjects"}
      </div>

      {/* forms to show */}
      {formToShow === 1 ?
        <StudentForm setFormData={setFormData} formData={formData} /> :
        formToShow === 2 ?
          <GuardianForm setFormData={setFormData} formData={formData} /> :
          <SubjectForm setFormData={setFormData} formData={formData} />}

      {/*  */}
      <div className="" >
        <div className=" flex justify-between pt-[2rem] " >
          <button disabled={formToShow > 1 ? false : true} onClick={() => handlePrevBtn()} className=" w-5/12  " >Previous</button>
          {formToShow === 3 ? <button className=" w-5/12 " onClick={(e: React.FormEvent<HTMLElement>) => handleFormSubmit(e)} >
            Register
          </button> : <button disabled={formToShow >= 3 ? true : false} onClick={() => handleNextBtn()} className=" w-5/12 " >Next</button>}
        </div>
        {/*  */}
        {/* <div className="" >
          {formToShow === 3 && <button className=" mt-[3rem] w-full " onClick={(e: React.FormEvent<HTMLElement>) => handleFormSubmit(e)} >
            Register
          </button>}
        </div> */}
      </div>
    </div>

    {loadingStatus.loading && <Loading message={loadingStatus.message} />}

  </div >
}


export default RegisterStudentQRCode;