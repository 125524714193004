import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { ROUTER_NAME_ADMIN_PAGE } from "../../../utils/browserRouter/routesNames";



const AdminLogin: React.FC = () => {
  const [formData, setFormData] = useState<any>(null);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const navigate = useNavigate();

  function handleFormSubmit(e: React.FormEvent<HTMLElement>) {
    try {
      e.preventDefault();
      navigate(ROUTER_NAME_ADMIN_PAGE);
    }
    catch (err: any) {
      console.log(err);
    }
  }
  function handleShowPassword(e: React.FormEvent<HTMLElement>) {
    try {
      e.preventDefault();
      setShowPassword(!showPassword);
    }
    catch (err: any) {
      console.log(err);
    }
  }

  return <div className=" px-[1rem] " >

    <div className=" mx-auto max-w-[800px]" >

      <div className=" pt-[2rem] mb-[2rem] text-3xl " >Admin Login</div>

      <form
      // onSubmit={(e: React.FormEvent<HTMLElement>) => handleFormSubmit(e)} 
      >
        <div className=" div_formfield flex flex-col " >
          <label >Email</label>

          <input onChange={(e) => setFormData({ ...formData, email: e.target.value })} placeholder="Enter email" />
        </div>

        <div className=" flex flex-col " >
          <label >Password</label>
          <div className=" flex justify-between" >
            <input className="w-[90%] mr-[0.5rem] " type={showPassword ? "text" : "password"} onChange={(e) => setFormData({ ...formData, email: e.target.value })} placeholder="Enter password" />
            <button className="w-1/12" onClick={(e) => handleShowPassword(e)} >show</button>
          </div>
        </div>

        {/*  */}
        <button className=" mt-[2rem] w-full" onClick={(e: React.FormEvent<HTMLElement>) => handleFormSubmit(e)} >Submit</button>
      </form>
    </div>
  </div>
}


export default AdminLogin;