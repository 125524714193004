export const CollegeProvince = [
  { value: 'gauteng', label: 'Gauteng' },
  { value: 'eastern cape', label: 'Eastern Cape' },
  { value: 'free state', label: 'Free State' },
  { value: 'kwazulu-natal', label: 'KwaZulu-Natal' },
  { value: 'limpopo', label: 'Limpopo' },
  { value: 'mpumalanga', label: 'Mpumalanga' },
  { value: 'northern cape', label: 'Northern Cape' },
  { value: 'north west', label: 'North West' },
];

export const GENDER_OPTIONS = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
  { value: 'other', label: 'Other' },
  { value: 'unknown', label: 'Prefer not to say' },
];

export const SUBJECTS: { subject: string }[] = [
  { subject: 'Accounting' },
  { subject: 'Business Studies' },
  { subject: 'Computer Applications Technology' },
  { subject: 'Life Sciences' },
  { subject: 'Mathematical Literacy' },
  { subject: 'Mathematics' },
  { subject: 'Physical Sciences' },
];
