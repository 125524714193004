import SelectComponent from "../../../../components/selectComponent";
import { FormComponentProps } from "../interfaces";


const DurationAccessLab = [
  { value: 'daily', label: 'Daily' },
  { value: 'weekly', label: 'Weekly' },
  { value: 'monthly', label: 'Monthly' },
];



const LabUsageForm: React.FC<FormComponentProps> = ({ formData, setFormData }) => {

  return <div className="" >
    <div className=" divform flex flex-col" >
      <label >Number of number with access to lab </label>
      <input placeholder="Enter number of student with access the lab"
        className=" w-full"
        type="number"
        min={0}
        onChange={(e) => setFormData({ ...formData, "numberStudentAccessLab": e.target.value })} />
    </div>

    <div className=" divform">
      <SelectComponent
        options={DurationAccessLab}
        onChange={(value) => setFormData({ ...formData, "labAccessDuration": value })}
        label="Duration of access"
      />
    </div>
  </div>
}

export default LabUsageForm;