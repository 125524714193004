import { useEffect, useState } from 'react'
import { get } from '../../utils/supabase/ops';
import { useNavigate } from 'react-router-dom';
import { ROUTER_NAME_ADD_ADMIN_PAGE, ROUTER_NAME_VIEW_SURVEYS_PAGE } from '../../utils/browserRouter/routesNames';

// 
function formatDateTime(dateTimeString: string) {
  const date = new Date(dateTimeString);

  const options: any = {
    month: 'long', // Full month name
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false // 24-hour format
  };

  const formattedDate = date.toLocaleDateString(undefined, options);
  const formattedTime = date.toLocaleTimeString(undefined, options);

  return `${formattedDate}`;
  return `${formattedDate} ${formattedTime}`;
}

function Admin() {
  const [learners, setLearners] = useState<any | null>(null);
  const navigate = useNavigate();

  async function fetch() {
    try {
      const data = await get();
      if (data) {
        setLearners(data);
      }
    }
    catch (err) {
      console.log(err);
    }
  }

  function handleAddAdminBtn() {
    try {
      navigate(ROUTER_NAME_ADD_ADMIN_PAGE);
    }
    catch (err: any) {
      console.log(err);
    }
  }

  useEffect(() => {
    // fetch all learners
    // fetch();
  }, []);


  return (<div className='admin' >
    <h2 >Admin Portal</h2>

    <div className=' my-[1rem] ' >
      <button className=' px-[1rem] ' onClick={() => handleAddAdminBtn()} >Add Admin</button>
      <button className=' ml-[0.5rem] px-[1rem] ' onClick={() => navigate(ROUTER_NAME_VIEW_SURVEYS_PAGE)} >View Surveys</button>
    </div>

    {/* <div className='number_learners' >
      <span >Number Of Learners</span>
      <span className='number' >{learners && learners.length}</span>
    </div>

    <div className='learners_container' >
      {
        learners && learners.map((learner: any, idx: number) => <div className='learners' key={idx}  >
          <div className='learner_info' >
            <div className='' >
              <div >Name: {learner.name}</div>
              <div >Gender: {learner.gender}</div>
              <div >Age: {learner.age}</div>
              <div >Eamil: {learner.email}</div>
            </div>
            <div className='' >
              <div >Education Level: {learner.education_level}</div>
              <div >Employee Status: {learner.employee_status}</div>
              <div >Province: {learner.province}</div>
            </div>
          </div>
          <div className='date' >Enrol Date: {formatDateTime(learner.created_at)}</div>
        </div>)
      }
    </div> */}
  </div>);
}

export default Admin;