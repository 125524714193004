import { COURSE_QUIZ_INTERFACE } from "../interfaces/coursesLectures";


export const COURSE1_QUIZ1: COURSE_QUIZ_INTERFACE[] = [
  {
    question: "Which of the following is an example of computer hardware?",
    answer: "CPU",
    section: "Some Theory",
    options: ["Microsoft Word", "Google Chrome", "CPU", "Windows 10"]
  },
  {
    question: "What is the main function of an operating system?",
    answer: "To manage the computer’s hardware and software resources",
    section: "Some Theory",
    options: ["To allow users to browse the internet", "To perform mathematical calculations", "To manage the computer’s hardware and software resources", "To provide graphic design tools"]
  },
  {
    question: "Which of the following is an example of a web browser?",
    answer: "Mozilla Firefox",
    section: "Some Theory",
    options: ["Microsoft Office", "Adobe Photoshop", "Mozilla Firefox", "Windows Media Player"]
  },
  {
    question: "What is the primary purpose of creating folders on a computer?",
    answer: "To organize and store files systematically",
    section: "Folders and Installations",
    options: ["To change the computer's wallpaper", "To organize and store files systematically", "To speed up the computer's processing power", "To protect the computer from viruses"]
  },
  {
    question: "Which of the following actions is NOT possible with a folder on a computer?",
    answer: "Change the folder's color",
    section: "Folders and Installations",
    options: ["Rename the folder", "Delete the folder", "Install software directly into the folder", "Change the folder's color"]
  },
  {
    question: "What is the purpose of a file extension (e.g., .docx, .jpg) in a file name?",
    answer: "To identify the file type and associate it with a specific program",
    section: "Folders and Installations",
    options: ["To provide a description of the file contents", "To identify the file type and associate it with a specific program", "To protect the file from being copied", "To determine the size of the file"]
  },
  {
    question: "What is the primary purpose of using email as a communication tool?",
    answer: "To send and receive messages, documents, and other files electronically",
    section: "Folders and Installations",
    options: ["To have real-time voice conversations", "To send and receive messages, documents, and other files electronically", "To post updates and share news with a large audience", "To make video calls"]
  },
  {
    question: "What does the term 'instant messaging' refer to?",
    answer: "Sending real-time text messages over the internet",
    section: "Folders and Installations",
    options: ["Sending messages via postal mail", "Sending real-time text messages over the internet", "Communicating through formal letters", "Sending pre-scheduled email messages"]
  },
  {
    question: "Which tool would be most appropriate for a team to collaborate on a shared document in real-time?",
    answer: "Google Docs",
    section: "Folders and Installations",
    options: ["Google Docs", "Adobe Photoshop", "Microsoft Excel (offline)", "PDF Reader"]
  }
];
