import React, { useState } from "react";

interface RadioButtonProps {
  label: string;
  value: string;
  isSelected: boolean;
  onChange: (value: string) => void;
}

const RadioButton: React.FC<RadioButtonProps> = ({
  label,
  value,
  isSelected,
  onChange,
}) => {
  return (
    <label className="inline-flex items-center cursor-pointer mr-[1rem] ">
      <input
        type="radio"
        value={value}
        checked={isSelected}
        onChange={() => onChange(value)}
        className="form-radio text-blue-600"
      />
      <span className="ml-2 text-gray-700">{label}</span>
    </label>
  );
};

export default RadioButton;
