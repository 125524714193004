import React, { useState } from "react";
import CollegeInfoForm from "./components/collegeForm";
import ResoucesForm from "./components/resourcesForm";
import MaintenanceForm from "./components/maintainForm";
import LabUsageForm from "./components/labUsageForm";
import ComplaintsForm from "./components/complaintsForm";
import InfrastructorForm from "./components/infrastructorForm";
import RateQualityForm from "./components/qualityInfrasForm";
import SuggestCommentForm from "./components/suggestionsForm";
import { validateFormReturn } from "./interfaces";
import { validateSurveyForm } from "./routines";
import Navbar from "../../../components/navbar";


const SurveyPage: React.FC = () => {
  const [formData, setFormData] = useState<any>({});
  const [formError, setFormError] = useState<string | null>(null);

  function onSubmit(e: React.FormEvent<HTMLElement>) {
    try {
      e.preventDefault();
      // check if form is valid
      console.log(formData);
      const isValidForm: validateFormReturn = validateSurveyForm(formData);
      if (isValidForm && isValidForm.status) {
        alert("valid form");
      }
      else {
        setFormError(isValidForm.message);
        alert(`${isValidForm.message}`);
      }
    }
    catch (err: unknown) {
      // log to database
      console.log(err);
    }
  }

  return <div className=" pt-[1rem] pb-[3rem] " id="survey_form" >
    <Navbar />

    <div className="mx-auto max-w-[800px] " >

      <div className=" text-2xl mt-[2rem] mb-[1rem] " >College Survey</div>

      <form className=" p-4" onSubmit={(e: React.FormEvent<HTMLElement>) => onSubmit(e)} >

        {/* Colleges Information */}
        <CollegeInfoForm formData={formData} setFormData={setFormData} />

        {/* 2. ICT Skills and Resources */}
        <ResoucesForm formData={formData} setFormData={setFormData} />

        {/* 3. Maintenance and Troubleshooting */}
        <MaintenanceForm formData={formData} setFormData={setFormData} />

        {/* 4. Lab Usage */}
        <LabUsageForm formData={formData} setFormData={setFormData} />

        {/* 5. Functioning and Complaints */}
        <ComplaintsForm formData={formData} setFormData={setFormData} />

        {/* 6. Equipment and Infrastructure */}
        <InfrastructorForm formData={formData} setFormData={setFormData} />

        {/* 6.5 Rate the Quality of the following */}
        <RateQualityForm formData={formData} setFormData={setFormData} />

        {/* 7 - comment and suggests*/}
        <SuggestCommentForm formData={formData} setFormData={setFormData} />

        {/*  */}
        <button className=" mt-[2rem] w-full"
          onClick={(e: React.FormEvent<HTMLElement>) => onSubmit(e)} >
          Submit
        </button>

      </form>


    </div>
  </div>
}

export default SurveyPage;